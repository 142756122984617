<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockStageSlider = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "content";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockStageSlider;
  blockPosition: number;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const cmsContent = getSlotContent("stageSlider");
</script>

<template>
  <CmsGenericElement :content="cmsContent" :blockPosition="blockPosition" />
</template>


<style lang="scss">
  .breadcrumb:has(+.stage-slider){
    @apply hidden;
  }
  .breadcrumb + .stage-slider {
    @apply important-mt-0;
  }
  #header[data-behaviour="sticky"] + .stage-slider {
    @apply mt-[-100px] lg:mt-0 xl:mt-[-104px] 3xl:mt-[-120px];
  }
</style>